@import "C:/Users/tanaka-k/Documents/fm-yokohama2022.git/resource/sass/module/__mixin.scss";
@import "C:/Users/tanaka-k/Documents/fm-yokohama2022.git/resource/sass/module/__variable.scss";
.path-dj {
  select {
    /* 初期化 */
    appearance: none;
    background: none;
    border: none;
    font-size: clamp(16px,3vw,20px);
    font-weight: 700;
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 0 10px;
  }


  .article {
    max-width: 1000px;
    margin: 20px auto 80px;

    .select-btn {
      background: $GRAY_000;
      height: 40px;
      width: clamp(200px,22vw,260px);
      margin: 40px auto 40px;
      box-sizing: border-box;
      border: 2px solid $GRAY_700;
      position: relative;
      z-index: 1;
      &::before {
        position: absolute;
        content: '';
        background: url(/img/common/ico_search.svg) left top no-repeat;
        width: clamp(20px,3vw,26px);
        height: clamp(18px,3vw,24px);
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
      }
      &::after {
        position: absolute;
        content: '';
        width: 10px;
        height: 10px;
        right: 10px;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        border-bottom: 2px solid $GRAY_700;
        border-right: 2px solid $GRAY_700;
        z-index: -1;
      }
    }

    .dj-wrap {
      margin-bottom: 40px;
      .article-title {
        background: $COLOR_MAIN;
        color: $GRAY_000;
        font-size: clamp(20px,3vw,28px);
        font-weight: 700;
        line-height: 1.5;
        padding: 8px 12px;
        margin-bottom: 12px;
      }
      .article-list {
        display: flex;
        gap: 12px 12px;
        flex-wrap: wrap;
        margin-left: 10px;
        &__item {
          flex-grow: 0;
          flex-shrink: 1;
          flex-basis: 110px;
          margin-bottom: 12px;
          a {
            color: $GRAY_700;
            figure {
              overflow: hidden;
              img {
                object-fit: cover;
                width: 100%;
                height: 110px;
                vertical-align: bottom;
              }
            }
            .txt {
              margin-top: 4px;
              font-size: clamp(14px,3vw,16px);
            }
            &:hover {
              figure {
                img {
                  filter: brightness(70%);
                  transform: scale(1.1,1.1);
                }
              }
              .txt {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}





/****************************************************
 PC scss
****************************************************/
@media screen and (min-width:768px) {
  .path-dj {
    .article {
      .dj-wrap {
        margin-bottom: 40px;
        .article-title {
        }
        .article-list {
          gap: 20px 20px;
          margin-left: 0;
          &__item {
            flex-basis: 184px;
            a {
              figure {
                img {
                  height: 184px;
                }
              }
            }
          }
        }
      }
    }
  }
}
