@charset "UTF-8";
/*====================================
	1. Color
====================================*/
.path-dj select {
  /* 初期化 */
  appearance: none;
  background: none;
  border: none;
  font-size: clamp(16px, 3vw, 20px);
  font-weight: 700;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 0 10px;
}

.path-dj .article {
  max-width: 1000px;
  margin: 20px auto 80px;
}

.path-dj .article .select-btn {
  background: #FFFFFF;
  height: 40px;
  width: clamp(200px, 22vw, 260px);
  margin: 40px auto 40px;
  box-sizing: border-box;
  border: 2px solid #121212;
  position: relative;
  z-index: 1;
}

.path-dj .article .select-btn::before {
  position: absolute;
  content: '';
  background: url(/img/common/ico_search.svg) left top no-repeat;
  width: clamp(20px, 3vw, 26px);
  height: clamp(18px, 3vw, 24px);
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

.path-dj .article .select-btn::after {
  position: absolute;
  content: '';
  width: 10px;
  height: 10px;
  right: 10px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  border-bottom: 2px solid #121212;
  border-right: 2px solid #121212;
  z-index: -1;
}

.path-dj .article .dj-wrap {
  margin-bottom: 40px;
}

.path-dj .article .dj-wrap .article-title {
  background: #001976;
  color: #FFFFFF;
  font-size: clamp(20px, 3vw, 28px);
  font-weight: 700;
  line-height: 1.5;
  padding: 8px 12px;
  margin-bottom: 12px;
}

.path-dj .article .dj-wrap .article-list {
  display: flex;
  gap: 12px 12px;
  flex-wrap: wrap;
  margin-left: 10px;
}

.path-dj .article .dj-wrap .article-list__item {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: 110px;
  margin-bottom: 12px;
}

.path-dj .article .dj-wrap .article-list__item a {
  color: #121212;
}

.path-dj .article .dj-wrap .article-list__item a figure {
  overflow: hidden;
}

.path-dj .article .dj-wrap .article-list__item a figure img {
  object-fit: cover;
  width: 100%;
  height: 110px;
  vertical-align: bottom;
}

.path-dj .article .dj-wrap .article-list__item a .txt {
  margin-top: 4px;
  font-size: clamp(14px, 3vw, 16px);
}

.path-dj .article .dj-wrap .article-list__item a:hover figure img {
  filter: brightness(70%);
  transform: scale(1.1, 1.1);
}

.path-dj .article .dj-wrap .article-list__item a:hover .txt {
  text-decoration: underline;
}

/****************************************************
 PC scss
****************************************************/
@media screen and (min-width: 768px) {
  .path-dj .article .dj-wrap {
    margin-bottom: 40px;
  }
  .path-dj .article .dj-wrap .article-list {
    gap: 20px 20px;
    margin-left: 0;
  }
  .path-dj .article .dj-wrap .article-list__item {
    flex-basis: 184px;
  }
  .path-dj .article .dj-wrap .article-list__item a figure img {
    height: 184px;
  }
}
